.modal {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.3s ease-in-out;
  }
  .modal.active {
    opacity: 1;
    visibility: visible;
    display: inline;
  }
  .modal__content {
    width: 350px;
    margin: 50px auto;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    transition: inherit;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100px);
  }
  .modal.active .modal__content {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .modal__header, .modal__body, .modal__footer {
    padding: 16px 20px;
  }
  .modal__close {
    position: absolute;
    top: 16px;
    right: 20px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #7f7f7f;
  }
  .modal__close:hover {
    color: #000;
  }
  